export const translationsEnglish = {
  /* Titles */
  Biografi: 'Biography',
  Skådespelaren: 'Actor',
  Dansaren: 'Dancer',
  Kontakt: 'Contact',
  Showreel: 'Showreel',
  Commercially: 'Commercially',
  /* Text */
  kommercielt:
    'Piotr has appeared in music videos including <i>Roxette by Jonas Åkerlund, Weeping Willows, Takida, Magnus Uggla</i> \n',
  biography:
    'Piotr Giro was born in Wałbrzych, Poland and moved to Sweden at the age of 7. He was trained at the Royal Swedish Ballet School in Stockholm and has been active since 1995 as an actor, dancer, and choreographer. \n \n He has worked with independent directors, choreographers, institutions, companies, and fixed ensembles within theater, dance, film, and television both in Sweden and abroad. \n \n Piotr has been active at the Royal Dramatic Theatre, Stockholm City Theatre, Unga Klara, Gothenburg City Theatre, Cirkus Cirkör, the Royal Swedish Opera, Norrlandsoperan, and Teater Giljotin. \n \n He has collaborated with directors such as Susanna Edwards, Suzanne Osten, Daniel Lind Lagerlöf, Simon Kaijser, Katrine Wiedemann, Eva Bergman, Jasenko Selimović, Etienne Glaser, and Niklas Hjulström. \n \n He has worked with world-renowned companies and choreographers such as Ultima Vez / Wim Vandekeybus, Batsheva Dance Company, Cullberg Ballet, La La La Human Steps / Édouard Lock, Ohad Naharin, Angelin Preljocaj, Josef Nadj, Örjan Andersson, Fredrik "Benke" Rydman, Jens Östberg, Helena Franzén, Bogdan & Carina, Ina Christel Johannessen, and Hans Marklund. ',
  Actor:
    'Piotr played the title role in <i>Romeo and Juliet</i> at the Royal Dramatic Theatre’s Elverket stage, a collaboration with Cirkus Cirkör, and in the title role in <i>Don Juan by Molière</i> at Gothenburg City Theatre. \n \n He played one of the lead roles in the film adaptation of <i>Keillers Park</i> and has since appeared in several films, including <i>The Girl, the Mother and the Demons, Welcome to Verona, The Man Under the Stairs, Skeppsholmen</i>. \n \n He has been active at the Royal Dramatic Theatre, Unga Klara, Kulturhuset Stadsteatern, Gothenburg City Theatre, Teater Giljotin, Norrland Operan. \n \n He has worked with directors such as Susanna Edwards, Suzanne Osten, Daniel Lind Lagerlöf, Simon Kaijser, Katrine Wiedemann, Eva Bergman, Jasenko Selimović, Etienne Glaser, Niklas Hjulström.',
  Dancer:
    'As a dancer, Piotr has performed repertoire and dance works for some of the world\'s largest dance companies and choreographers including <i>Ultima Vez / Wim Vandekeybus, Batsheva Dance Company, Cullberg Ballet, La La La Human Steps / Édouard Lock, Ohad Naharin, Angelin Preljocaj, Josef Nadj, Örjan Andersson, Fredrik "Benke" Rydman, Jens Östberg, Helena Franzén, Bogdan & Carina, Ina Christel Johannessen, Carl-Olof Berg, Hans Marklund. </i>',
  Dancerpt2:
    'Piotr has collaborated with musicians <i>David Byrne</i> and with <i>Marc Ribot</i> during his time with Ultima Vez in Belgium, \n in the fall of 2012 he assisted <i>Håkan Hellström</i> with movement for his Sweden tour. \n \n',
  Dancer1: '2019 - choreographed NK-Galan at Eric Ericsonhallen.',
  Dancer2: '2014 - choreographed Azerbaijan\'s Eurovision entry for the artist <i>Dilara Kazimova</i> - Start A Fire.',
  Dancer3: '2010 - Choreographed Pippi Longstocking at the Swedish Theatre in Helsinki.',
  STkontaktaMig: 'Contact directly',
  STkontakta1: ' or visit',
  STkontakta: 'Stockholmsgruppen',
  STkontakta2: 'for bookings: +46 (0)8 5100 1000 \n',
  /* Contact */
  Submit: 'Thank you, I will get back to you as soon as possible!',
  Email: 'Email',
  Name: 'Name',
  Message: 'Message',
  /* CV Headers */
  CVHeader1: 'Film & TV',
  CVHeader2: 'Theater & Stage',
  CVHeader3: 'Dance',
  CVHeader4: 'Other',
  CVHeader5: 'Commercial & Music Videos',
  /* CV Text */
  Actor1: '2018 - Dance First / TriArt Film / Directed by - Rikard Svensson',
  Actor2: '2016 - The Girl, the Mother and the Demons / Fundament Film / Directed by - Suzanne Osten',
  Actor3: '2015 - Anatomy of Revenge / Directed by - Reich + Szyber',
  Actor4: '2014 - The Ceremony / Svt documentary / Directed by - Lina Mannheimer',
  Actor5: '2010 - Coffee / Svt drama / Directed by - Helena Franzén',
  Actor6: '2009 - The Man Under the Stairs / Svt drama / Directed by - Daniel Lind Lagerlöf',
  Actor7: '2008 - Olof 1440 min / iFilm / Directed by - Johan Ribe',
  Actor8: '2007 - The Blueberry War / Mañana Film AB / Directed by - Lars-Göran Pettersson',
  Actor9: '2006 - Keillers Park / Film i Väst / Directed by - Susanna Edwards',
  Actor10: '2006 - Welcome to Verona / Filmlance & Metronome / Directed by - Suzanne Osten',
  Actor11: '2004 - Graveyard Island / Agadir Film / Directed by - Martin Forsström & Daniel Möllberg',
  Actor12: '2003 - Skeppsholmen / Svt drama / Directed by - Simon Kaijser',
  Actor13: '2003 - Five Floors / Svt drama / Directed by - Hanna Andersson',
  Actor14: '2002 - In Spite Of Wishing And Wanting / Film in Vlaanderen / Directed by - Wim Vandekeybus ',
  Actor15: '2001 - Mantra / Svt drama / Directed by - Jörgen Hjerdt',
  Actor16: '2000 - Inasmuch / Ultima Vez & Quasi Modo / Directed by - Wim Vandekeybus',
  Actor17: '1999 - The Last Words / Ultima Vez & Flemish Film / Directed by - Wim Vandekeybus',
  Actor18: '2017 - Because I’m worth it - (<i>The Man in the couple</i>) - Kulturhuset Stadsteatern',
  Actor19: '2015 - 2017 - Jungle Disaster - (<i>Nikos Skalkottas</i>) - Musikaliska in Stockholm, Atalante in Gothenburg',
  Actor20: '2010 - Eaten by Men by Reich + Szyber - Nantes, France',
  Actor21: '2009 - 2011 - Unknown Pleasures by Reich + Szyber - Pusterviksteatern - Orionteatern - Kulturhuset Stadsteatern, Sweden tour',
  Actor22: '2008 - Edvard the Second - (<i>Spencer</i>) - Unga Klara - Kulturhuset Stadsteatern',
  Actor23: '2006 - Momo or the battle for time - (<i>Gigi</i>) - Cirkus Cirkör - Kulturhuset Stadsteatern',
  Actor24: '2006 - Vagabond by Piotr Giro - Teatergiljotin - MADE-festival - Umeå ',
  Actor25: '2005 - 99% Unknown - Cirkus Cirkör - Sweden, Belgium, Denmark tour',
  Actor26: '2004 - Propaganda - Away team - Jens Östberg - Kulturhuset Stadsteatern',
  Actor27: '2004 - Don Juan by Molière - (<i>Don Juan</i>) - Gothenburg City Theatre',
  Actor28: '2003 - 2004 - Dante’s Divine Comedy - Gothenburg City Theatre',
  Actor29: '2002 - 2003 - Romeo & Juliet by W. Shakespeare - (<i>Romeo</i>) - Royal Dramatic Theatre - Guest performance in Vienna, Austria',
  Dans1: '2016 - Ina Christel Johannessen - Kulturhuset Stadsteatern',
  Dans2: '2016 - Louise Crnkovic-Friis - Århus, Denmark',
  Dans3: '2014 - 2015 - Bo.Le Ro by Shintaro Oue, Piotr Giro - Singapore, Malaysia tour',
  Dans4: '2014 - Cullberg Ballet - Sweden, Belgium tour',
  Dans5: '2014 - Jill Johnson - Hans Marklund - Hamburger Börs',
  Dans6: '2013 - Eurovision - interlude Northern lights - Fredrik Rydman',
  Dans7: '2012 - Royal Swedish Opera, Riddar Bluebeard’s Castle / Trouble in Tahiti',
  Dans8: '2011 - 2012 - Lena Philipsson - My Dream Show - Fredrik Rydman',
  Dans9: '2009 - 2010 - Equal by Shintaro Oue, Piotr Giro, Satoshi Kudo - Sweden, Europe tour',
  Dans95: '2008 - 2009 - Ultima Vez / Wim Vandekeybus - Spiegel - Brussels, Belgium',
  Dans10: '2007 - 2008 - Örjan Andersson - NorrlandsOperan - Dansens hus',
  Dans11: '2006 - Vagabond by Piotr Giro - Teatergiljotin - MADE-festival - Umeå',
  Dans12: '2005 - Cirkus Cirkör - 99% Unknown - Sweden, Belgium, Denmark tour',
  Dans13: '2005 - Renovation object by Piotr Giro, Shintaro Oue - London Opera',
  Dans14: '2004 - Philippe Blanchard - Dansens hus - Lyon, France',
  Dans15: '2000 - 2001 - Josef Nadj - Centre Chorégraphique National d’Orléans - France',
  Dans16: '1998 - 2009 - Ultima Vez / Wim Vandekeybus - Brussels, Belgium',
  Dans17: '1998 - Norrdans - Jens Östberg - Jo Strømgren - Sweden tour',
  Dans175: '1997 - Angelin Preljocaj / Batsheva Dance Company - Nuptials',
  Dans18: '1996 - 1998 - Batsheva Dance Ensemble / Ohad Naharin - Tel Aviv, Israel',
  Dans19: '1996 - Carl - Olof Berg - ÄETT, Deuce',
  Dans20: '1995 - Royal Swedish Opera - Ballet Peer Gynt',
  Dans21: '1995 - Royal Swedish Opera - Opera Aida',
  Commercial1: '2016 - Weeping Willows / My Love Is Not Blind / Directed by - David Kaijser',
  Commercial15: '2016 - Magnus Uggla / Dyngrak(VR) / Directed by - Unknown',
  Commercial2: '2015 - Elin Lanto / Burning Heart / Directed by - Robin Robinovich ',
  Commercial25: '2015 - Ikea commercial /More space for the children / Directed by - Jens Jonsson',
  Commercial3: '2014 - Takida / To Have And To Hold / Directed by - Bengt-Anton Runsten',
  Commercial4: '2011 - Elin Lanto / Color Me Blue / Directed by - Rafael Edholm ',
  Commercial5: '2006 - Backyard Babies / The Mess Age (How Could I Be So Wrong) Sony Music',
  Commercial6: '2002 - Roxette / A Thing About You / Directed by - Jonas Åkerlund',
  Commercial7: '1996 - Thomas Di Leva / The Happy Star / Directed by - Di Leva',
  Commercial8: '1993 - Pandora / Trust Me / Directed by - Unknown',
  Skills1: 'Swedish (native) - English (fluent) - Polish',
  Skills2: 'Been a professional dancer since 1995',
  Skills3: 'Trains martial arts, acrobatics, climbing, and practices Zazen since 2010',
  Skills35: 'Plays Shakuhachi Japanese bamboo flute',
  Skills4: 'Height - 185 cm',
  Skills5: 'Driver\'s license B',
};

