export const translationSvenska = {
  /* Titles */
  Biografi: 'Biografi',
  Skådespelaren: 'Skådespelaren',
  Dansaren: 'Dansaren',
  Kontakt: 'Kontakt',
  Showreel: 'Showreel',
  Commercially: 'Kommersiellt',
  /* Text */
  kommercielt:
    'Piotr har medverkat i musikvideor bland annat i <i>Roxette av Jonas Åkerlund, Weeping Willows, Takida, Magnus Uggla</i> \n',
  biography:
    'Piotr Giro är född i Wałbrzych, Polen och kom till Sverige vid 7 års åldern. Han är utbildad på Kungliga Svenska Balettskolan i Stockholm och har varit verksam sedan 1995 som skådespelare, dansare och koreograf. \n \n Han har arbetat med fria regissörer, koreografer, institutioner, kompanier och fasta ensembler inom, teater, dans, film och tv både i Sverige och utomlands. \n \n Piotr har varit verksam på Dramaten, Stockholms Stadsteater, Unga Klara, Göteborgs Stadsteater, Cirkus Cirkör, Kungliga Operan, Norrlandsoperan, Teater Giljotin. \n \n Jobbat med regissörer som Susanna Edwards, Suzanne Osten, Daniel Lind Lagerlöf, Simon Kaijser, Katrine Wiedemann, Eva Bergman, Jasenko Selimović, Etienne Glaser, Niklas Hjulström. \n \n Han har framfört verk och turnerat med världskompanier och koreografer som Ultima Vez / Wim Vandekeybus, Batsheva Dance Company, Cullbergbaletten, La La La Human Steps / Édouard Lock, Ohad Naharin, Angelin Preljocaj, Josef Nadj, Örjan Andersson, Fredrik ”Benke” Rydman, Jens Östberg, Helena Franzén, Bogdan & Carina, Ina Christel Johannessen, Hans Marklund. ',
  Actor:
    'Piotr spelade titelrollen i <i>Romeo och Julia</i> på Dramatens scen Elverket, ett samarbete med Cirkus Cirkör, och i titelrollen i <i>Don Juan av Molière</i> på Göteborgs Stadsteater. \n \n Han spelade en av huvudrollerna i filmatiseringen av <i>Keillers Park</i> och har därefter medverkat i ett antal filmer, bland annat <i>Flickan, mamman och demonerna, Wellkåmm to Verona, Mannen Under Trappan, Skeppsholmen</i>.  \n \n Han har varit verksam på Dramaten, Unga Klara, Kulturhuset Stadsteatern, Göteborgs Stadsteater, Teater Giljotin, Norrland Operan. \n \n Jobbat med regissörer som Susanna Edwards, Suzanne Osten, Daniel Lind Lagerlöf, Simon Kaijser, Katrine Wiedemann, Eva Bergman, Jasenko Selimović, Etienne Glaser, Niklas Hjulström.',
  Dancer:
    'Som dansare har Piotr framfört repertoar och dansverk till några av världens största  danskompanier och koreografer bland annat <i>Ultima Vez / Wim Vandekeybus, Batsheva Dance Company, Cullbergbaletten, La La La Human Steps / Édouard Lock, Ohad Naharin, Angelin Preljocaj, Josef Nadj, Örjan Andersson, Fredrik ”Benke” Rydman, Jens Östberg, Helena Franzén, Bogdan & Carina, Ina Christel Johannessen, Carl-Olof Berg, Hans Marklund. </i>',
  Dancerpt2:
    'Piotr har samarbetat med musikerna <i>David Byrne</i> och med <i>Marc Ribot</i> under tiden med Ultima Vez i Belgien, \n hösten 2012 hjälpte han <i>Håkan Hellström</i> med rörelse inför sin Sverige turné. \n \n',
  Dancer1: '2019 - koreograferade NK-Galan på Eric Ericsonhallen.',
  Dancer2: '2014 - koreograferade Azerbaijans Eurovision bidrag till artisten <i>Dilara  Kazimova</i> - Start A Fire.',
  Dancer3: '2010 - Koreograferade Pippi Långstrump på Svenska Teatern i Helsingfors.',
  STkontaktaMig: 'Kontakta direkt',
  STkontakta1: ' eller besök',
  STkontakta: 'Stockholmsgruppen',
  STkontakta2: 'för bokningar: +46 (0)8 5100 1000 \n',
  /* Contact */
  Submit: 'Tack, Jag kommer höra av mig så snart som möjligt!',
  Email: 'E-post',
  Name: 'Namn',
  Message: 'Meddelande',
  /* CV Headers */
  CVHeader1: 'Film & TV',
  CVHeader2: 'Teater & Scen',
  CVHeader3: 'Dans',
  CVHeader4: 'Övrigt',
  CVHeader5: 'Kommersiellt & Musikvideor',
  /* CV Text */
  Actor1: '2018 - Dansa Först / TriArt Film / Regi - Rikard Svensson',
  Actor2: '2016 - Flickan, mamman och demonerna / Fundament Film / Regi - Suzanne Osten',
  Actor3: '2015 - Hämndens Anatomi / Regi - Reich + Szyber',
  Actor4: '2014 - Ceremonin / Svt dokumentär / Regi - Lina Mannheimer',
  Actor5: '2010 - Kaffe / Svt drama / Regi - Helena Franzén',
  Actor6: '2009 - Mannen Under Trappan / Svt drama / Regi - Daniel Lind Lagerlöf',
  Actor7: '2008 - Olof 1440 min / iFilm / Regi - Johan Ribe',
  Actor8: '2007 - Blåbärskriget / Mañana Film AB / Regi - Lars-Göran Pettersson',
  Actor9: '2006 - Keillers Park / Film i Väst / Regi - Susanna Edwards',
  Actor10: '2006 - Wellkåmm to Verona / Filmlance & Metronome / Regi - Suzanne Osten',
  Actor11: '2004 - Kyrkogårdsön / Agadir Film / Regi - Martin Forsström & Daniel Möllberg',
  Actor12: '2003 - Skeppsholmen / Svt drama / Regi - Simon Kaijser',
  Actor13: '2003 - Fem Trappor / Svt drama / Regi - Hanna Andersson',
  Actor14: '2002 - In Spite Of Wishing And Wanting / Film in Vlaanderen / Regi - Wim Vandekeybus ',
  Actor15: '2001 - Mantra / Svt drama / Regi - Jörgen Hjerdt',
  Actor16: '2000 - Inasmuch / Ultima Vez & Quasi Modo / Regi - Wim Vandekeybus',
  Actor17: '1999 - The Last Words / Ultima Vez & Flemish Film / Regi - Wim Vandekeybus',
  Actor18: '2017 - Because I’m worth it - (<i>Mannen i paret</i>) - Kulturhuset Stadsteatern',
  Actor19:
    '2015 - 2017 - Katastrof i djungeln - (<i>Nikos Skalkottas</i>) - Musikaliska i Stockholm, Atalante i Göteborg',
  Actor20: '2010 - Eaten by Men av Reich + Szyber - Nantes, Frankrike',
  Actor21:
    '2009 - 2011 - Unknown Pleasures av Reich + Szyber - Pusterviksteatern - Orionteatern - Kulturhuset Stadsteatern, Sverige turné',
  Actor22: '2008 - Edvard den Andre - (<i>Spencer</i>) - Unga Klara - Kulturhuset Stadsteatern',
  Actor23: '2006 - Momo eller kampen om tiden - (<i>Gigi</i>) - Cirkus Cirkör - Kulturhuset Stadsteatern',
  Actor24: '2006 - Vagabond av Piotr Giro - Teatergiljotin - MADE-festivalen - Umeå ',
  Actor25: '2005 - 99% Unknown - Cirkus Cirkör - Sverige, Belgien, Danmark turné',
  Actor26: '2004 - Propaganda - Bortalaget - Jens Östberg - Kulturhuset Stadsteatern',
  Actor27: '2004 - Don Juan av Moliére - (<i>Don Juan</i>) - Göteborgs stadsteater',
  Actor28: '2003 - 2004 - Dantes Gudomliga Komedi - Göteborgs stadsteater',
  Actor29:
    '2002 - 2003 - Romeo & Julia av W. Shakespeare - (<i>Romeo</i>) - Dramaten - Gästspel i Wien, Österrike',
  Dans1: '2016 - Ina Christel Johannessen - Kulturhuset Stadsteatern',
  Dans2: '2016 - Louise Crnkovic-Friis - Århus, Danmark',
  Dans3: '2014 - 2015 - Bo.Le Ro av Shintaro Oue, Piotr Giro - Singapore, Malaysia turné',
  Dans4: '2014 - Cullbergbaletten - Sverige, Belgien turné',
  Dans5: '2014 - Jill Johnson - Hans Marklund - Hamburger Börs',
  Dans6: '2013 - Eurovision - mellanakt Norrsken - Fredrik Rydman',
  Dans7: '2012 - Kungliga Operan, Riddar Blåskäggs borg / Trouble in Tahiti',
  Dans8: '2011 - 2012 - Lena Philipsson - Min Drömshow - Fredrik Rydman',
  Dans9: '2009 - 2010 - Equal av Shintaro Oue, Piotr Giro, Satoshi Kudo - Sverige, Europa turné',
  Dans95: '2008 - 2009 - Ultima Vez / Wim Vandekeybus - Spiegel - Bryssel, Belgien',
  Dans10: '2007 - 2008 - Örjan Andersson - NorrlandsOperan - Dansens hus',
  Dans11: '2006 - Vagabond av Piotr Giro - Teatergiljotin - MADE-festivalen - Umeå',
  Dans12: '2005 - Cirkus Cirkör - 99% Unknown - Sverige, Belgien, Danmark turné',
  Dans13: '2005 - Renoveringsobjekt av Piotr Giro, Shintaro Oue - London Opera',
  Dans14: '2004 - Philippe Blanchard - Dansens hus - Lyon, Frankrike',
  Dans15: '2000 - 2001 - Josef Nadj - Centre Chorégraphique National d’Orléans - Frankrike',
  Dans16: '1998 - 2009 - Ultima Vez / Wim Vandekeybus - Bryssel, Belgien',
  Dans17: '1998 - Norrdans - Jens Östberg - Jo Strømgren - Sverige turné',
  Dans175: '1997 - Angelin Preljocaj / Batsheva Dance Company - Noces',
  Dans18: '1996 - 1998 - Batsheva Dance Ensemble / Ohad Naharin - Tel-Aviv, Israel',
  Dans19: '1996 - Carl - Olof Berg - ÄETT, Deuce',
  Dans20: '1995 - Kungliga Operan - Baletten Peer Gynt',
  Dans21: '1995 - Kungliga Operan - Operan Aida',
  Commercial1: '2016 - Weeping Willows / My Love Is Not Blind / Regi - David Kaijser',
  Commercial15: '2016 - Magnus Uggla / Dyngrak(VR) / Regi - Unknown',
  Commercial2: '2015 - Elin Lanto / Burning Heart / Regi - Robin Robinovich ',
  Commercial25: '2015 - Ikea reklam /Mer plats åt barnen / Regi - Jens Jonsson',
  Commercial3: '2014 - Takida / To Have And To Hold / Regi - Bengt-Anton Runsten',
  Commercial4: '2011 - Elin Lanto / Color Me Blue / Regi - Rafael Edholm ',
  Commercial5: '2006 - Backyard Babies / The Mess Age (How Could I Be So Wrong) Sony Music',
  Commercial6: '2002 - Roxette / A Thing About You / Regi - Jonas Åkerlund',
  Commercial7: '1996 - Thomas Di Leva / Den Glada Stjärnan / Regi - Di Leva',
  Commercial8: '1993 - Pandora / Trust Me / Regi - Unknown',
  Skills1: 'Svenska (modersmål) - Engelska (flytande) - Polska',
  Skills2: 'Varit professionell dansare sedan 1995',
  Skills3: 'Tränar kampsport, akrobatik, klättring och är utövare av Zazen sedan 2010',
  Skills35: 'Spelar Shakuhachi japansk bambuflöjt',
  Skills4: 'Längd - 185 cm',
  Skills5: 'Körkort B',
};
